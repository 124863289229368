import { Card, FlexBox, Typography } from "@vp/swan";
import { useLogger, useSwanStyleKeys, useUserContext } from "@vp/ubik-context";

interface Address {
  id: string;
}

export interface Props {
  addresses: Address[];
}

export const Fragment = (props: Props) => {
  const logger = useLogger();
  const { locale } = useUserContext();
  logger.log("Rendering fragment for a locale", locale);

  useSwanStyleKeys(["core"]);

  return (
    <FlexBox>
      <Card style={{ width: "100%" }}>
        <Typography textAllCaps fontWeight="bold" mb={1} mr={1}>
          Saved Addresses
        </Typography>
        {props.addresses.map((address) => (
          <Typography key={address.id} mb={1}>
            {address.id}
          </Typography>
        ))}
      </Card>
    </FlexBox>
  );
};
